<script>
import { GlBadge, GlButton, GlDisclosureDropdown, GlDisclosureDropdownGroup } from '@gitlab/ui';
import GitlabVersionCheckBadge from '~/gitlab_version_check/components/gitlab_version_check_badge.vue';
import { helpPagePath } from '~/helpers/help_page_helper';
import { FORUM_URL, PROMO_URL } from '~/constants';
import { __ } from '~/locale';
import { STORAGE_KEY } from '~/whats_new/utils/notification';
import Tracking from '~/tracking';
import { DROPDOWN_Y_OFFSET, HELP_MENU_TRACKING_DEFAULTS, duoChatGlobalState } from '../constants';

export default {
  components: {
    GlBadge,
    GlButton,

    GlDisclosureDropdown,
    GlDisclosureDropdownGroup,
    GitlabVersionCheckBadge,
  },
  mixins: [Tracking.mixin({ property: 'nav_help_menu' })],
  i18n: {
    help: __('Help'),
    support: __('About Qyber'),
    explore: __('Explore projects'),
    groups: __('Explore groups'),
    topics: __('Explore topics'),
    catalog: __('Explore CI/CD catalog'),
    snippets: __('Explore snippets'),
    shortcuts: __('Keyboard shortcuts'),
    minio: __('MinIO'),
    mattermost: __('Mattermost'),
    svc: __('SVC'),
    discord: __('Qyber Discord server'),
    info: __('Info Projects'),
    github: __('GitHub'),
    linkedin: __('LinkedIN'),
    youtube: __('YouTube'),
    odysee: __('Odysee'),
    facebook: __('Facebook'),
    xtwitter: __('X/Twitter'),
    version: __('Your GitLab version'),
    whatsnew: __("What's new"),
    terms: __('Terms and privacy'),
    privacy: __('Privacy statement'),
  },
  inject: ['isSaas'],
  props: {
    sidebarData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showWhatsNewNotification: this.shouldShowWhatsNewNotification(),
      duoChatGlobalState,
      toggleWhatsNewDrawer: null,
    };
  },
  computed: {
    itemGroups() {
      const groups = {
        helpLinks: {
          items: [
            {
              text: this.$options.i18n.help,
              href: helpPagePath(),
              extraAttrs: {
                ...this.trackingAttrs('help'),
              },
            },
            {
              text: this.$options.i18n.support,
              href: 'https://qyber.black/qyber/qyber',
              extraAttrs: {
                ...this.trackingAttrs('support'),
              },
            },
            {
              text: this.$options.i18n.info,
              href: 'https://qyber.black/explore/projects/topics/info',
              extraAttrs: {
                ...this.trackingAttrs('info'),
              },
            },
            {
              text: this.$options.i18n.explore,
              href: 'https://qyber.black/explore',
              extraAttrs: {
                ...this.trackingAttrs('explore'),
              },
            },
            {
              text: this.$options.i18n.groups,
              href: 'https://qyber.black/explore/groups',
              extraAttrs: {
                ...this.trackingAttrs('groups'),
              },
            },
            {
              text: this.$options.i18n.topics,
              href: 'https://qyber.black/explore/projects/topics',
              extraAttrs: {
                ...this.trackingAttrs('topics'),
              },
            },
            {
              text: this.$options.i18n.catalog,
              href: 'https://qyber.black/explore/catalog',
              extraAttrs: {
                ...this.trackingAttrs('catalog'),
              },
            },
            {
              text: this.$options.i18n.snippets,
              href: 'https://qyber.black/explore/snippets',
              extraAttrs: {
                ...this.trackingAttrs('snippets'),
              },
            },
            {
              text: this.$options.i18n.minio,
              href: 'https://dc.qyber.black/',
              extraAttrs: {
                ...this.trackingAttrs('minio'),
              },
            },
            {
              text: this.$options.i18n.mattermost,
              href: 'https://mm.qyber.black/',
              extraAttrs: {
                ...this.trackingAttrs('mattermost'),
              },
            },
            {
              text: this.$options.i18n.svc,
              href: 'https://svc.qyber.black/',
              extraAttrs: {
                ...this.trackingAttrs('svc'),
              },
            },
            {
              text: this.$options.i18n.discord,
              href: 'https://discord.gg/Bxueq5qt',
              extraAttrs: {
                ...this.trackingAttrs('discord'),
              },
            },
            {
              text: this.$options.i18n.github,
              href: 'https://github.com/qyber-black',
              extraAttrs: {
                ...this.trackingAttrs('github'),
              },
            },
            {
              text: this.$options.i18n.linkedin,
              href: 'https://www.linkedin.com/company/qyberblack',
              extraAttrs: {
                ...this.trackingAttrs('linkedin'),
              },
            },
            {
              text: this.$options.i18n.youtube,
              href: 'https://www.youtube.com/@qyber',
              extraAttrs: {
                ...this.trackingAttrs('snippets'),
              },
            },
            {
              text: this.$options.i18n.odysee,
              href: 'https://odysee.com/@qyber',
              extraAttrs: {
                ...this.trackingAttrs('odysee'),
              },
            },
            {
              text: this.$options.i18n.facebook,
              href: 'https://www.facebook.com/qyberblack',
              extraAttrs: {
                ...this.trackingAttrs('facebook'),
              },
            },
            {
              text: this.$options.i18n.xtwitter,
              href: 'https://twitter.com/QyberBlack',
              extraAttrs: {
                ...this.trackingAttrs('xtwitter'),
              },
            },
            this.isSaas && {
              text: this.$options.i18n.privacy,
              href: `${PROMO_URL}/privacy`,
              extraAttrs: {
                ...this.trackingAttrs('privacy'),
              },
            },
            this.sidebarData.terms &&
              !this.isSaas && {
                text: this.$options.i18n.terms,
                href: this.sidebarData.terms,
                extraAttrs: {
                  ...this.trackingAttrs('terms'),
                },
              },
          ].filter(Boolean),
        },
        helpActions: {
          items: [
            {
              text: this.$options.i18n.shortcuts,
              action: () => {},
              extraAttrs: {
                class: 'js-shortcuts-modal-trigger',
                'data-track-action': 'click_button',
                'data-track-label': 'keyboard_shortcuts_help',
                'data-track-property': HELP_MENU_TRACKING_DEFAULTS['data-track-property'],
              },
              shortcut: '?',
            },
            this.sidebarData.display_whats_new && {
              text: this.$options.i18n.whatsnew,
              action: this.showWhatsNew,
              count:
                this.showWhatsNewNotification &&
                this.sidebarData.whats_new_most_recent_release_items_count,
              extraAttrs: {
                'data-track-action': 'click_button',
                'data-track-label': 'whats_new',
                'data-track-property': HELP_MENU_TRACKING_DEFAULTS['data-track-property'],
              },
            },
          ].filter(Boolean),
        },
      };

      if (this.sidebarData.show_version_check) {
        groups.versionCheck = {
          items: [
            {
              text: this.$options.i18n.version,
              href: helpPagePath('update/_index.md'),
              version: `${this.sidebarData.gitlab_version.major}.${this.sidebarData.gitlab_version.minor}`,
              extraAttrs: {
                ...this.trackingAttrs('version_help_dropdown'),
              },
            },
          ],
        };
      }

      return groups;
    },
    updateSeverity() {
      return this.sidebarData.gitlab_version_check?.severity;
    },
  },
  methods: {
    shouldShowWhatsNewNotification() {
      if (
        !this.sidebarData.display_whats_new ||
        localStorage.getItem(STORAGE_KEY) === this.sidebarData.whats_new_version_digest
      ) {
        return false;
      }
      return true;
    },

    async showWhatsNew() {
      this.showWhatsNewNotification = false;

      if (!this.toggleWhatsNewDrawer) {
        const { default: toggleWhatsNewDrawer } = await import(
          /* webpackChunkName: 'whatsNewApp' */ '~/whats_new'
        );
        this.toggleWhatsNewDrawer = toggleWhatsNewDrawer;
        this.toggleWhatsNewDrawer(this.sidebarData.whats_new_version_digest);
      } else {
        this.toggleWhatsNewDrawer();
      }
    },

    trackingAttrs(label) {
      return {
        ...HELP_MENU_TRACKING_DEFAULTS,
        'data-track-label': label,
      };
    },

    trackDropdownToggle(show) {
      this.track('click_toggle', {
        label: show ? 'show_help_dropdown' : 'hide_help_dropdown',
      });
    },
  },
  dropdownOffset: { mainAxis: DROPDOWN_Y_OFFSET },
};
</script>

<template>
  <gl-disclosure-dropdown
    :dropdown-offset="$options.dropdownOffset"
    @shown="trackDropdownToggle(true)"
    @hidden="trackDropdownToggle(false)"
  >
    <template #toggle>
      <gl-button
        category="tertiary"
        icon="question-o"
        class="super-sidebar-help-center-toggle btn-with-notification"
        data-testid="sidebar-help-button"
      >
        <span
          v-if="showWhatsNewNotification"
          data-testid="notification-dot"
          class="notification-dot-info"
        ></span>
        {{ $options.i18n.help }}
      </gl-button>
    </template>

    <gl-disclosure-dropdown-group
      v-if="sidebarData.show_version_check"
      :group="itemGroups.versionCheck"
    >
      <template #list-item="{ item }">
        <span class="gl-flex gl-flex-col gl-leading-24">
          <span class="gl-text-sm gl-font-bold">
            {{ item.text }}
            <gl-emoji data-name="rocket" />
          </span>
          <span>
            <span class="gl-mr-2">{{ item.version }}</span>
            <gitlab-version-check-badge v-if="updateSeverity" :status="updateSeverity" />
          </span>
        </span>
      </template>
    </gl-disclosure-dropdown-group>

    <gl-disclosure-dropdown-group
      :group="itemGroups.helpLinks"
      :bordered="sidebarData.show_version_check"
    />

    <gl-disclosure-dropdown-group :group="itemGroups.helpActions" bordered>
      <template #list-item="{ item }">
        <span class="-gl-my-1 gl-flex gl-items-center gl-justify-between">
          {{ item.text }}
          <gl-badge v-if="item.count" pill variant="info">{{ item.count }}</gl-badge>
          <kbd v-else-if="item.shortcut" class="flat">?</kbd>
        </span>
      </template>
    </gl-disclosure-dropdown-group>
  </gl-disclosure-dropdown>
</template>
